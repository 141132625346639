.chkbox {
  margin-right: 10px;
}
.checkboxLabel {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
}

input[type="checkbox"] {
  display: none;
}
input[type="checkbox"] + span {
  display: inline-block;
  position: relative;
  top: 0px;
  width: 20px;
  height: 20px;
  margin: 0px 0px 0 0;
  vertical-align: middle;
  background: #C9C9C9 left top no-repeat;
  border-radius: 3px;
  cursor: pointer;
}
input[type="checkbox"]:checked + span {
  background: #42B8B2 -19px top no-repeat;
}

input[type="checkbox"] + span {
  margin-right: 24px;
}
@media only screen and (max-width: 414px) and (max-width: 736px) {

  .checkboxLabel {
    line-height: 1.428571429;
  }
}
@media only screen and (max-width: 375px) and (max-width: 767px) {
  .checkboxLabel {
    line-height: 1.3;
    font-size: 15px;
  }

}

