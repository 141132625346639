.statspage {
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: inherit;
  justify-content: space-around;
  padding-bottom: 4px;
}
.statspage h1 {
  font-size: 26px;
  text-transform: uppercase;
  margin: 26px 0 19px 0;
}
.statspage h3 {
  font-size: 16px;
  text-transform: uppercase;
}

.statspage .sectionButtons {
  display: flex;
}

.statspage .sectionButtons .button--secondary{
  border: none;
  background: transparent;
}
.statspage .sectionButtons button{
  margin: 0 2px;
}
.statspage .sectionButtons button:first-child{
  margin-left: 0;
}
 .statspage .sectionButtons button:last-child {
  margin-right: 0;
}

.statspage p {
  font-size: 18px;
}
.statspage .margtop {
  margin-top: 20px;
}

.statspage .largeText {
  font-size: 50px;
}

.statspage .brdr {
  height: 1px;
  background-color: rgba(29,29,29,0.1);
  margin-bottom: 15px;
  width: 100%;
}

.statsButtons {
  text-align: left;
}


@media only screen and (max-width: 500px){
  .statspage .sectionButtons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .statspage .sectionButtons button {
      margin:0;
      margin-top: 6px;
      flex: 0 0 49%;
  }

  .statspage .sectionButtons button:nth-child(2n+1) {

    margin-right: 1%;
  }
}