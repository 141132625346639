.answer {
  align-items: center;
  background-color: #EFEFEF;
  border-radius: 3px;
  color: #1D1D1D;
  cursor: pointer;
  display: flex;
  height: 86px;
  justify-content: flex-start;
  margin-bottom: 2px;
  padding: 0 0 0 20px;
}

.answer:not(:last-child){
  border-bottom: none;
}

.answer:focus {
  background-color: #42B8B2;
  color: #fff;
}
.answer:focus h3{
  color: #fff;
}

.answerDisabled {
  pointer-events: none;
}
.answer > * {
  margin: 0 5px;
}
.answer h3 {
  font-size: 24px;
  min-width: 19px;
}
.answer p {
  line-height: 1.25;
  margin-left: 18px;
  padding: 0;
}

.answerA, .answerC {
  margin-right: 3px;
}
.answerB, .answerD {
  margin-left: 3px;
}
.answerA, .answerB {
  margin-bottom: 3px;
}
.answerC, .answerD {
  margin-top: 3px;
  margin-bottom: 6px;
}
@media only screen and (max-width: 768px){
  .answerA, .answerC {
    margin-right: 0px;
  }
  .answerB, .answerD {
    margin-left: 0px;
  }
  .answerC, .answerD {
    margin-bottom: 3px;
  }
}
@media only screen and (max-width: 414px) and (max-width: 736px) {
  .checkbox-answer .answer {
    height: 86px;
    padding: 0 0 0 12px;
  }
}
@media only screen and (max-width: 390px) and (max-width: 844px) {

  .answer {
    height: 94px;
  }

}

@media only screen and (max-width: 375px) and (max-width: 767px) {
  .answer h3 {
    font-size: 20px;
  }
  .answers p {
    font-size: 15px;
  }

  .answer {
    height: 86px;
  }

  .checkbox-answer .answer {
    height: 75px;
  }

}

