.Logo {
  display: flex;
  padding-bottom: 16px;
  margin-top: 8px;
  justify-content: center;
}
.maincontainer {
  display: block;
}

.mainpage {
  text-align: center;
}
.mainpage h1 {
  font-size: 32px;
  text-transform: uppercase;
  margin: 26px 0 19px 0;
}
.mainpage p {
  font-size: 18px;
}
.mainpage .margtop {
  margin-top: 8px;
}

.mainpage .intro {
  overflow: auto;
  margin-bottom: 0;
}
.mainpage .answers {
  flex-direction: row-reverse;
}
.mainpage .answers a {
  flex: 1;
}
.mainpage .answers .button--secondary {
  margin-left: 12px;
}
.mainpage .answers .button--primary {
  margin-right: 12px;
}
.maincontainer .imgheader {
  overflow: hidden;
}
.bluelink {
  color: #1a0dab;
  margin-top: 10px;
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
}
@media only screen and (max-width: 768px){
  .mainpage .answers .button--secondary {
    margin-left: 0px;
  }
  .mainpage .answers .button--primary {
    margin-right: 0px;
  }
  .mainpage .answers a {
    width: 100%;
  }
}

@media only screen and (max-width: 375px) and (max-height: 767px) {
  .Logo {
    margin-top: 12px;
  }
  .mainpage h1 {
    font-size: 22px;
    margin: 16px 0 14px 0;
    line-height: 1.45;
  }
  .mainpage p {
    font-size: 16px;
    margin-bottom: 19px;
    line-height: 1.5;
    padding: 0 10px;
  }
  .mainpage .margtop {
    margin-top: 4px;
  }

}

@media only screen and (min-width: 768px) and (max-height: 727px) {
  .maincontainer .imgheader {
    height: 250px;
  }
}

@media only screen and (min-width: 768px) and (max-height: 620px) {
  .maincontainer .imgheader {
    height: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-height: 520px) {
  .maincontainer .imgheader {
    height: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-height: 460px) {
  .maincontainer .imgheader {
    height: 60px;
  }
}