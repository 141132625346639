.progressbar {
  width: 100%;
  background-color: rgba(66, 184, 178, 0.5);
  border-radius: 3px;
  height: 12px;
}

.progressbar>div {
  background-color: #42B8B2;
  height: 12px;
  border-radius: 3px;
}

@media only screen and (max-width: 768px){
  .progressbar {
    height: 10px;
  }
  .progressbar>div {
    height: 10px;
  }
}