.question {
  text-align: center;
}
.question h1 {
  font-family: 'AvinorMedium';
  font-weight: 500;
  font-size: 28px;
  margin:23px auto 9px auto;
  line-height: 1.428571429;
  max-width: 640px;
}
.question h2 {
  font-size: 24px;
  text-transform: uppercase;
  margin:28px auto 9px auto;
  max-width: 620px;
}
.question p {
  font-size: 18px;
  color: #4E4E4E;
  margin-bottom: 20px;
}
@media only screen and (max-width: 768px){

  .question h2 {
    font-size: 18px;
  }
  .question p {
    font-size: 14px;
    margin-bottom: 23px;
  }
  .question h1 {
    font-size: 22px;
    margin:58px 0 9px 0;
    line-height: 1.428571429;
  }
}

@media only screen and (max-width: 414px) and (max-width: 736px) {
  .question p {
    font-size: 14px;
    margin-bottom: 2px;
  }
  .question h1 {
    font-size: 18px;
    margin:6px 0 9px 0;
    padding: 0 16px;
    line-height: 1.444444444;
  }
  .question h2 {
    margin:18px 0 5px 0;
  }
}

@media only screen and (max-width: 375px) and (max-width: 767px) {
  .question h1 {
    font-size: 16px;
    margin:6px 0 9px 0;
    padding: 0 12px;
    line-height: 1.35;
  }
  .question h2 {
    font-size: 16px;
    margin:10px 0 5px 0;
  }
  .question p {
    font-size: 14px;
    margin-bottom: 2px;
  }
}

