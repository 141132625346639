
main {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
}
.ci {
  align-items: center;
}
.answers {
  margin-bottom: 24px;
  position: absolute;
  bottom: 0;
  width: calc(100% - 16px );
  background-color: #F8F8F8;
  max-width: 820px;
  z-index: 1000;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.answers .margtop {
  margin-top: 0;
}

.showanswers {
  display: none;
}
.answers .answer {
  display: flex;
  width: calc( 50% - 23px);
}

@media only screen and (max-width: 768px){
  .answers {
    display: block;
    margin-bottom: 8px;
  }
  .answers .margtop {
    margin-top: 8px;
  }
  .showanswers {
    display: block;
  }
  .answers .answer {
    display: none;
    width: calc(100% - 20px );
  }
  .quizanswers.answers .answer {
    display: flex;
    width: calc(100% - 20px );
  }
  .checkbox-answer .answer {
    display: flex;
    width: calc(100% - 12px );
  }

  .answers.showanswer .answer {
    display: flex;
  }
}


@media only screen and (max-width: 375px) and (max-width: 767px) {

  .answers {
    margin-bottom: 4px;
    width: calc(100% - 8px );
  }
  .answers.quizanswers {
    margin-bottom: 0px;
  }

}


