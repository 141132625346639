.feedback-container {
  position: fixed;
  top:0;
  left:0;
  width: calc( 100% - 16px);
  height: calc( 100% - 32px );
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #1D1D1D;
  background-color: #fff;
  padding: 8px 8px 32px 8px;
  z-index: 1011;
}
.feedback-text {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  max-width: 820px;
  margin: 0 auto;
}

.thumbimg {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-end;
}

.feedback-text h2 {
  font-size: 24px;
  font-family: 'AvinorBold';
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 45px;
}

.feedback-text p {
  font-size: 20px;
  text-align: center;
  max-width: 640px;
}
.feedback-container .bottomPos {
  margin-top: auto;
  max-width: 820px;
}
.feedback-points {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 253px;
  margin-bottom: 20px;
}
.feedback-points .brdr {
  height: 1px;
  background-color: rgba(29,29,29,0.1);
  margin-bottom: 15px;
  width: 100%;
}
.feedback-points h4{
  font-size: 22px;
  font-family: 'AvinorMedium';
  text-transform: uppercase;
  margin-top: 18px;
}
.feedback-points h4.addpoints {
  font-family: 'AvinorBold';
  color: #42B8B2;
}

.thumbimg {
  margin-bottom: 20px;
  width: 50px;
}
@media only screen and (max-width: 768px){
  .feedback-container {
    height: calc( 100% - 16px );
    padding: 8px;
  }
  .feedback-text p {
    font-size: 18px;
  }
}
@media only screen and (max-width: 414px) and (max-width: 736px) {
  .feedback-text h2 {
    margin-bottom: 24px;
  }
  .feedback-points h4{
    margin-top: 12px;
  }
}


@media only screen and (max-width: 375px) and (max-width: 767px) {
  .feedback-container {
    width: calc( 100% - 8px);
    height: calc( 100% - 8px );
    padding: 4px;
  }
  .feedback-text p {
    font-size: 17px;
    line-height: 1.411764706;
  }


  .feedback-text h2 {
    font-size: 20px;
    margin-bottom: 24px;
  }
  .feedback-points h4{
    font-size: 20px;
  }
}
