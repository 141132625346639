.button {
  font-family: 'AvinorBold','Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
  border: 1px solid #84216B;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  width: 100%;
}
.button:disabled,
.button[disabled]{
  border: 1px solid transparent;
  background-color: rgba(132,33, 107, 0.25);
  color: #fff;
  cursor: default;
}
.button--primary {
  background-color: #84216B;
  color: #fff;

}
.button--secondary {
  color: #84216B;
  background-color: #fff;
}

.button--tertiar {
  color:#fff;
  background-color: #1D1D1D;
}

.button--small {
  font-size: 12px;
  padding: 10px 16px;

}
.button--medium {
  font-size: 16px;
  padding: 19px 20px;
}
.button--large {
  font-size: 18px;
  padding: 12px 24px;

}

@media only screen and (max-width: 375px) and (max-width: 767px) {

  .button--medium {
    font-size: 16px;
    padding: 16px;
  }

}
