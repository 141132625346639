.collapsedAnswers {
  position: absolute;
  bottom: 8px;
  width: calc(100% - 16px);
  background-color: #fff;
  max-width: 820px;
  z-index: 1000;
}

.showanswer {
  z-index: 100;
}
.forcezindex {
  z-index: 1000;
}
@media only screen and (max-width: 390px) and (max-width: 844px) {
  .collapsedAnswers {
    width: calc(100% - 16px);
  }
}

@media only screen and (max-width: 375px) and (max-width: 767px) {
  .collapsedAnswers {
    width: calc(100% - 8px);
    bottom: 4px;
  }
}
