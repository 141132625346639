.video-js {
  z-index: 1 !important;
}

.video-js,
.video-js .vjs-tech {
  background-color: #fff;
}

.video-js .vjs-tech {
  z-index: 0;
}

.vjs-big-play-centered .vjs-big-play-button {
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  margin: 0;
  padding: 0;
  width: 64px;
  border-radius: 50%;
  height: 64px;
  line-height: 2;
  background-color: #84216b;
  border: none;
}
.vjs-icon-placeholder {
  color: #fff;
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
  margin-top: 1px;
}

.vjs-icon-replay:before,
.video-js .vjs-play-control.vjs-ended .vjs-icon-placeholder:before {
  background-color: #84216b;
}
.video-js:hover .vjs-big-play-button,
.video-js .vjs-big-play-button:focus {
  background-color: #84216b;
}
.vjs-poster {
  background-color: #f8f8f8;
}

.vjs-ended .vjs-big-play-button {
  display: block !important;
}
