header {
  margin: 0;
  display: flex;
  align-items: center;
}

.task {
  font-family: 'AvinorBold';
  font-size: 18px;
  width: 70px;
  text-align: right;
}
@media only screen and (max-width: 768px){
  .task {
    font-size: 14px;
  }
}
