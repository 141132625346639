.grid-row {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.grid-row-2 {
  height: 50%;
}
.grid-row-6 {
  height: 14.285714286%;
}
.grid-row-7 {
  height: 14.285714286%;
}
.grid-container {
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

.grid-cell {
  border-left: 2px solid #fff;
  border-top: 2px solid #fff;
  flex: 1;
}

.grid-cell-selected {
  background-color: rgba(66,184,178,0.7);
}

@media only screen and (max-width: 768px){
  .grid-cell {
    border-left: 1px solid #fff;
    border-top: 1px solid #fff;
    flex: 1;
  }

}
