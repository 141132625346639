.imgholder {
  position: relative;
  overflow: hidden;
  min-height: 20px;
}
.imgholder.full {
  flex: 1;
}
.imgholder img {
  width: 100%;
  height: auto;
}

.image-play {
  position: absolute;
  top: calc( 50% - 25px);
  left: calc( 50% - 50px);
}

.image-fullscreen {
  position: absolute;
  right: 25px;
  top: 25px;
  cursor:pointer;
}

.imgholder.fullscreen {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,0.7);
}


.imgholder.fullscreen .imgcontainer {
  position: relative;
  width: 100%;
}
.imgholder.fullscreen .imgcontainer  .image-fullscreen {
  top: 25px;
}

.imgcontainer area {
  cursor: default;
}

.imggrid {
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom: 128px;
}

.imgbg{
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 0;
  position: relative;
  flex: 1;
  margin: 0 auto;
}
.imgmap {
  flex: 1;
}
.imground {
  border-radius: 3px;
  display: block;
}
@media only screen and (max-width: 375px) and (max-width: 767px) {
  .imgmap1 {
    width: 90%;
    margin: 0 auto;
  }
}