.article {
  position: absolute;
  top:0;
  left:0;
  width: calc( 100% - 16px);
  min-height: calc( 100% - 40px );
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #1D1D1D;
  background-color: #fff;
  padding: 8px 8px 32px 8px;
  z-index: 1011;
}
.article-text {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  max-width: 820px;
  margin: 0 auto;
}

.article-text h2 {
  margin-top: 54px;
  font-size: 24px;
  font-family: 'AvinorBold';
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 30px;
  width: 100%;
}

.article-text p {
  font-size: 20px !important;
  text-align: left;
  max-width: 640px;
  margin-top: 20px;
  padding: 0;
}

.article .bottomPos {
  margin-top: auto;
  max-width: 820px;
}

@media only screen and (max-width: 768px){
  .article {
    min-height: calc( 100% - 8px );
    height: auto;
    width: calc( 100% - 8px);
    padding: 4px;
  }
  .article-text p {
    margin-bottom: 0;
    line-height: 1.5;
    padding: 0px;
    font-size: 16px !important;
    margin-top: 10px;
  }
}
@media only screen and (max-width: 414px) and (max-width: 736px) {
  .article-text h2 {
    margin-bottom: 24px;
  }

}
