.stats {
  padding: 20px;
  background: white;
  margin-bottom: 8px;
  border: 1px solid #efefef;
  border-radius: 3px;
}
.stats h1 {
  font-size: 32px;
  text-transform: uppercase;
  margin: 50px 0 19px 0;
}

.stats h3 {
  font-size: 18px;
  text-transform: uppercase;
}

.stats p {
  font-size: 18px;
}

.stats .largeText {
  font-family: "AvinorLight", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 64px;
  color: #5fc3be;
  margin-top: 20px;
}
.stats .teamscore .largeText {
  color: #470a59;
}
.stats {
  padding: 20px;
}

.stats .brdr {
  height: 1px;
  background-color: rgba(29, 29, 29, 0.1);
  max-width: 253px;
  margin: 30px auto;
  width: 100%;
}

.stats .userTable {
  display: flex;
  flex-direction: column;
  max-width: 620px;
  margin: 30px auto;
}

.stats .userRow {
  display: flex;
  justify-content: space-between;
  margin: 0 0 20px 0;
}

.score .userRow div {
  text-transform: uppercase;
}

@media only screen and (max-width: 768px) {
  .stats {
    padding: 20px;
  }
  .stats h3 {
    font-size: 16px;
  }

  .stats .largeText {
    font-size: 54px;
  }
  .stats h1 {
    font-size: 26px;
  }
}
