

@font-face {
  font-family: 'AvinorLight';
  src: local('AvinorLight'), url(./fonts/Avinor-Light.woff2) format('woff2'), url(./fonts/Avinor-Light.woff) format('woff');
  font-weight: 300;
}

@font-face {
  font-family: 'AvinorMedium';
  src: local('Avinor'), url(./fonts/Avinor-Medium.woff2) format('woff2'), url(./fonts/Avinor-Medium.woff) format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'AvinorBold';
  src: local('AvinorBold'), url(./fonts/Avinor-Bold.woff2) format('woff2'), url(./fonts/Avinor-Bold.woff) format('woff');
  font-weight: 800;
}


@import-normalize;


html {
  box-sizing: border-box;
  font-size: 16px;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

body {
  margin: 0;
  font-family: 'AvinorMedium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F8F8F8;
}
html, body, #root {
  height: 100%;
  overflow: hidden;
}
html.scrollBody, .scrollBody body, .scrollBody #root {
  height: auto;
  overflow: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3 {
  font-family: 'AvinorBold';
  font-weight: 700;
  color: #1D1D1D;
}

p {
  line-height: 1.428571429;
  font-size: 16px;
  padding: 0 10px;
}

.color-primar {
  color: #470A59
}

.color-second {
  color: #FFBB00
}

.color-tertiar {
  color: #42B8B2
}

.container {
  margin: auto;
  max-width: 820px;
  width: calc( 100% - 16px);
  padding: 20px 0 8px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #F8F8F8;
}
@media only screen and (min-width: 768px) and (max-height: 700px){
  html, body, #root {
    height: auto;
  }
  html, body, #root{
    overflow: auto;
    padding: 0;
    margin: 0;
  }
}
@media only screen and (max-width: 768px){
  .container {
    margin: auto;
    max-width: 820px;
    width: calc( 100% - 16px);
    padding: 8px 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #F8F8F8;
  }
}

@media only screen and (max-width: 375px) and (max-width: 767px) {

  .container {
    width: calc( 100% - 8px);
    padding: 4px 0;
  }

}
